import {
  EXTRA_PROPERTY_CONFIGURATION_BASE_FRAGMENT,
} from '@/graphql/_Fragments/ExtraPropertyConfiguration/Base';

export const EXTRA_PROPERTY_SECTION_FULL_FRAGMENT = `
  fragment extraPropertySectionFullFragment on ExtraPropertySection {
    uid
    schemaCode
    name
    displaySequence
    configsInSection {
      ...extraPropertyConfigurationBaseFragment
    }
  }
  ${EXTRA_PROPERTY_CONFIGURATION_BASE_FRAGMENT}
`;
